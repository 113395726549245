type TrustedByLogos = {
  src: string
  alt: string
  className?: string
}

export const TRUSTEDBYLOGOS: TrustedByLogos[] = [
  {
    src: '/images/landing-page/trusted-by/bioderma.png',
    alt: 'bioderma.',
  },
  {
    src: '/images/landing-page/trusted-by/coca-cola.png',
    alt: 'coca-cola',
  },
  {
    src: '/images/landing-page/trusted-by/colgate-palmolive.png',
    alt: 'colgate-palmolive',
    className: '!max-h-10 sm:!max-h-12 md:!max-h-12 !max-w-[200px]',
  },
  {
    src: '/images/landing-page/trusted-by/eczacıbası.png',
    alt: 'eczacıbası',
  },
  {
    src: '/images/landing-page/trusted-by/eti.png',
    alt: 'eti',
    className: '!max-h-10 sm:!max-h-12 md:!max-h-12',
  },
  {
    src: '/images/landing-page/trusted-by/ferrero.png',
    alt: 'ferrero',
  },
  {
    src: '/images/landing-page/trusted-by/getir.png',
    alt: 'getir',
  },
  {
    src: '/images/landing-page/trusted-by/hayat.png',
    alt: 'hayat',
    className: '!max-h-10 sm:!max-h-12 md:!max-h-12',
  },
  // {
  //   src: '/images/landing-page/trusted-by/henkel.png',
  //   alt: 'henkel',
  // },
  {
    src: '/images/landing-page/trusted-by/nestle.png',
    alt: 'nestle',
    className: '!max-h-10 sm:!max-h-12 md:!max-h-12',
  },
  {
    src: '/images/landing-page/trusted-by/pepsico.png',
    alt: 'pepsico',
  },
  {
    src: '/images/landing-page/trusted-by/png.png',
    alt: 'png',
  },
  {
    src: '/images/landing-page/trusted-by/trendyol.png',
    alt: 'trendyol',
  },
  {
    src: '/images/landing-page/trusted-by/unilever.png',
    alt: 'unilever',
    className: '!max-h-10 sm:!max-h-12 md:!max-h-12',
  },
  {
    src: '/images/landing-page/trusted-by/vodafone.png',
    alt: 'vodafone',
  },
  // {
  //   src: '/images/landing-page/trusted-by/jnj.png',
  //   alt: 'jnj',
  // },
  {
    src: '/images/landing-page/trusted-by/arcelik.png',
    alt: 'arcelik',
  },
  {
    src: '/images/landing-page/trusted-by/barilla.png',
    alt: 'barilla',
  },
  {
    src: '/images/landing-page/trusted-by/borusan-otomotiv.png',
    alt: 'borusan-otomotiv',
  },
  {
    src: '/images/landing-page/trusted-by/pazarama.png',
    alt: 'pazarama',
  },
  {
    src: '/images/landing-page/trusted-by/cci.png',
    alt: 'coca cola icecek',
    className: '!max-h-10 sm:!max-h-12 md:!max-h-12',
  },
  {
    src: '/images/landing-page/trusted-by/dnp.png',
    alt: 'dnp',
  },
  // {
  //   src: '/images/landing-page/trusted-by/gratis.png',
  //   alt: 'gratis',
  //   className: '!max-h-10 sm:!max-h-12 md:!max-h-12',
  // },
  {
    src: '/images/landing-page/trusted-by/migros.png',
    alt: 'migros',
    className: '!max-h-10 sm:!max-h-12 md:!max-h-12',
  },
  {
    src: '/images/landing-page/trusted-by/iffco.png',
    alt: 'iffco',
  },
  {
    src: '/images/landing-page/trusted-by/kenvue.png',
    alt: 'kenvue',
  },
  {
    src: '/images/landing-page/trusted-by/lipton.png',
    alt: 'lipton',
    className: '!max-h-10 sm:!max-h-12 md:!max-h-12',
  },
  {
    src: '/images/landing-page/trusted-by/lookfantastic.png',
    alt: 'lookfantastic',
  },
  {
    src: '/images/landing-page/trusted-by/media-markt.png',
    alt: 'media-markt',
  },
  {
    src: '/images/landing-page/trusted-by/mizanplus.png',
    alt: 'mizanplus kitchens',
  },
  {
    src: '/images/landing-page/trusted-by/monster.png',
    alt: 'monster notebook',
  },
  // {
  //   src: '/images/landing-page/trusted-by/ninja.png',
  //   alt: 'ninja',
  // },
  {
    src: '/images/landing-page/trusted-by/pampa.png',
    alt: 'pampa',
  },
  {
    src: '/images/landing-page/trusted-by/carvak.png',
    alt: 'carvak',
  },
  {
    src: '/images/landing-page/trusted-by/reckitt.png',
    alt: 'reckitt',
    className: '!max-h-10 sm:!max-h-12 md:!max-h-12 mb-4',
  },
  {
    src: '/images/landing-page/trusted-by/salonsdirect.png',
    alt: 'salonsdirect',
    className: '!max-h-10 sm:!max-h-12 md:!max-h-12 max-w-[200px]',
  },
  {
    src: '/images/landing-page/trusted-by/watsons.png',
    alt: 'watsons',
  },
]
