import anime from 'animejs'
import { useRef } from 'react'
import { useInViewEffect } from 'react-hook-inview'

type BackgroundIconsAnimationsProps = {
  hideIcons?: boolean
}

const BackgroundIconsAnimations = (props: BackgroundIconsAnimationsProps) => {
  const backgroundIcons = useRef([
    '/images/landing-page/hero-section/bg-icon-1.svg',
    '/images/landing-page/hero-section/bg-icon-2.svg',
    '/images/landing-page/hero-section/bg-icon-3.svg',
    '/images/landing-page/hero-section/bg-icon-4.svg',
    '/images/landing-page/hero-section/bg-circle-icon-1.svg',
    '/images/landing-page/hero-section/bg-circle-icon-2.svg',
    '/images/landing-page/hero-section/bg-circle-icon-3.svg',
    '/images/landing-page/hero-section/bg-circle-icon-4.svg',
  ])

  const ref = useInViewEffect(([entry], observer) => {
    if (entry.isIntersecting) {
      observer.unobserve(entry.target)

      const backgroundIconsElements = document.querySelectorAll(
        '#background-icons-animation .bg-icon'
      )

      backgroundIconsElements.forEach((icon, i) => {
        anime({
          targets: icon,
          translateY: {
            value: () => [
              anime.random(window.innerHeight - 200, window.innerHeight - 100),
              0,
            ],
            duration: 10000,
          },
          translateX: {
            value: () => anime.random(0, Math.min(window.innerWidth, 1200)),
            duration: 0,
          },
          rotate: () => anime.random(-45, 45),
          opacity: {
            value: [0, 1],
            duration: 1000,
          },
          duration: () => anime.random(10000, 15000),
          easing: 'easeOutSine',
          loop: true,
          delay: () => 1000 * i,
          complete: () => {
            anime({
              targets: icon,
              opacity: [1, 0],
              duration: 1000,
              easing: 'linear',
            })
          },
        })
      })
    }
  })

  const filteredBackroundIcons = props.hideIcons
    ? backgroundIcons.current.slice(4, 7)
    : backgroundIcons.current

  return (
    <div
      ref={ref}
      id="background-icons-animation"
      className="absolute inset-0 -z-10 overflow-hidden"
    >
      {filteredBackroundIcons.map((icon, index) => (
        <img
          key={index}
          src={icon}
          alt="Background Icon"
          className="bg-icon pointer-events-none absolute left-0 top-0 -z-10 w-8 md:w-16"
        />
      ))}
    </div>
  )
}

export default BackgroundIconsAnimations
