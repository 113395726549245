import anime from 'animejs'
import Link from 'next/link'
import { useEffect, useRef } from 'react'

import useAnimationFrame from '@/hooks/use-animation-frame'

type Props = {
  title: string | string[]
  description: string | string[]
}

const CallToActionSection = ({ title, description }: Props) => {
  const cursorPosition = useRef({
    x: 0,
    y: 0,
  })

  useAnimationFrame(() => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches

    if (isMobile) {
      const button = document.getElementById('cta-button')

      if (button) {
        button.style.transform = `translate3d(0, 0, 0)`
        button.style.position = `unset`
      }

      return
    }

    anime({
      targets: '#cta-section .cta-button',
      translateX: cursorPosition.current.x - 80,
      translateY: cursorPosition.current.y - 80,
      duration: 100,
      easing: 'easeOutQuad',
    })

    anime({
      targets: '#cta-section .button-shadow',
      translateX: cursorPosition.current.x - 80,
      translateY: cursorPosition.current.y - 80,
      duration: 120,
      easing: 'easeOutQuad',
    })
  })

  useEffect(() => {
    const section = document.getElementById('cta-section')

    if (!section) return

    const onMouseMove = (e: MouseEvent) => {
      const isMobile = window.matchMedia('(max-width: 768px)').matches

      if (isMobile) {
        cursorPosition.current = {
          x: 80,
          y: 80,
        }

        return
      }

      cursorPosition.current = {
        x: e.clientX - section.getBoundingClientRect().left,
        y: e.clientY - section.getBoundingClientRect().top,
      }
    }

    window.addEventListener('mousemove', onMouseMove)

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [])

  return (
    <section
      id="cta-section"
      className="relative isolate flex max-h-sm-screen w-full flex-col items-center justify-center overflow-hidden bg-green-50 py-64 md:py-96"
    >
      <div className="mx-auto flex h-full max-w-container flex-col items-center justify-center gap-16">
        <div className="flex max-w-5xl flex-col items-center gap-7 text-brand-50">
          <h2
            id="cta-title"
            className="max-w-4xl text-center text-3xl/snug font-semibold !text-zinc-700 md:text-4xl/snug lg:text-5xl/snug"
          >
            {title}
          </h2>

          <h2
            id="cta-description"
            className="max-w-4xl text-center !text-zinc-400 sm:text-base md:px-5 md:text-lg lg:text-xl"
          >
            {description}
          </h2>
        </div>
        <Link
          id="header-cta"
          href="/contact-us"
          className="max-w-[200px] items-center justify-center rounded-full bg-brand-600 px-4 py-2 text-center text-lg text-slate-50 duration-200 hover:bg-brand-500 focus:bg-brand-500 active:bg-brand-700 md:flex md:px-6 md:py-3"
        >
          Request a Demo
        </Link>
      </div>
    </section>
  )
}

export default CallToActionSection
